<template>
      <v-col>
          <v-card class="fill-height" style="background-color:#F5F5F5;" :loading="patientEditLoadStatus===1 || patientEditStatus===1">
            <v-toolbar style="background-color:#F5F5F5;" flat>
              <v-btn color="primary" small @click="close()"><v-icon>mdi-arrow-left</v-icon>Wróć</v-btn>
              
              <v-spacer></v-spacer>
              <v-toolbar-title><h3>{{ editedItem.surname?editedItem.surname.toUpperCase():'' }} {{ editedItem.name?editedItem.name.toUpperCase():'' }}{{editedItem.age?'/':''}}{{ editedItem.age }} {{editedItem.phone?' - ':''}} {{ editedItem.phone_prefix=='48'?editedItem.phone:("+"+editedItem.phone_prefix+" "+editedItem.phone) }}</h3></v-toolbar-title>
              
              <v-dialog v-if="user.permissions['patient'] >= 10" v-model="dialog" max-width="1000px">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small class="mb-2 mt-2 ml-3" dark color="secondary">
                        Edytuj
                      </v-btn>
                    </template>
                    <patient-editor item-editing="true" @close="closeDialog"></patient-editor>
              </v-dialog>
            </v-toolbar>

          <v-row class="mt-0">
            <v-col class="col-7 pl-5 pt-0" v-resize="onResize">
               <med-flow :flowHeight="windowSize.y" />
            </v-col>
            <v-col class="col-5 pl-0 pr-5 pt-0">
               <v-card class="mb-5" style="background-color:#D8D8D8;">
                <v-toolbar flat style="background-color:#D8D8D8;" >
                  <v-toolbar-title>Zaplanowane działania</v-toolbar-title>
                </v-toolbar>
                <v-simple-table
                  class="mx-3 pb-5"   
                  style="background-color:#D8D8D8;"                 
                  >
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="item in editedItem.planned_appointments"
                          :key="item.id"
                          
                        >
                          <td><v-avatar size="35" :color="item.category.color"><v-icon size="20">{{item.category.icon}}</v-icon></v-avatar></td>
                          <td nowrap><b>{{ item.date_from }}</b></td>
                          <td><b>{{ item.service.name }}</b><br />{{item.clinic?item.clinic.name:''}}</td>
                          <td><i>{{ _.find(lookups.appointment_status,{'value':item.status}).text }}</i></td>
                          <td><v-btn :to="{ name: 'dzialanie', params: { id: item.id } }" color="primary" x-small>Przejdź</v-btn></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
              </v-card>

              <v-card :loading="taskEditStatus===1"  class="mb-5" style="background-color:#D8D8D8;">
                <v-toolbar flat style="background-color:#D8D8D8;">
                  <v-toolbar-title>Zadania</v-toolbar-title>
                  <v-spacer />
                  <v-checkbox v-model="showDone" class="mt-4" label="Zakończone"></v-checkbox>
                </v-toolbar>
                <tasks-list edit-mine  />
              </v-card>

              <v-card class="mb-5">
                <v-toolbar flat color="white" >
                  <v-toolbar-title>Saldo</v-toolbar-title>
                  <v-btn x-small class="ml-2" color="secondary" :to="{name:'rozliczenia',params:{patient_id:editedItem.id}}">rozliczenia</v-btn>
                  <v-spacer />
                  <v-toolbar-title><span :style="'color:' + (editedItem.balance>0?'red;':'green;')"><b>{{ editedItem.balance}} {{_.find(lookups.currencies,{'value':editedItem.currency}).text}}</b></span></v-toolbar-title>
                </v-toolbar>
              </v-card>

              <v-card>
                <log-preview :type="1" :ref_id="editedItem.id" />
              </v-card>

            </v-col>
          </v-row>

        </v-card>
      </v-col>
</template>

<script>
  import MedFlow from '../components/MedFlow.vue';
  import PatientEditor from '../components/PatientEditor'
  import TasksList from '../components/TasksList.vue';
  import LogPreview from '../components/LogPreview.vue';

  export default {
    components:{
      PatientEditor,
      MedFlow,
      TasksList,
      LogPreview
    },
    data: () => ({
      windowSize: {
        x: 0,
        y: 0,
      },
      dialog:false,
    }),
    validations: {
    
    },
    watch: {
      patientEditStatus(val){
        if(val===2){
          this.closeDialog();
          //this.close();
        }
      },
      patientEditLoadStatus(val){
        if(val===2){
            var options = this.$store.getters.getFlowOptions;
            options.toDate = null;
            options.fromDate = null;
            options.selectedAppointment = null;
            options.selectedPatient = this.editedItem.id;
            this.$store.dispatch( 'setFlowOptions', {options:  options} );
          }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      editedItem(){
        return this.$store.getters.getPatientEdit;
      },
      patientEditLoadStatus(){
        return this.$store.getters.getPatientEditLoadStatus();
      },
      patientEditStatus(){
        return this.$store.getters.getPatientEditStatus();
      },
      patientEditText(){
        return this.$store.getters.getPatientEditText
      },
      showDone: {
        get () {
          return this.$store.getters.getTaskOptions.showDone;
        },
        set (value) {
          var currentOptions = {...this.$store.getters.getTaskOptions};
          currentOptions.showDone = value;
          this.$store.dispatch( 'setTaskOptions', {options:  currentOptions} );
        }
      },
      taskEditStatus(){
        return this.$store.getters.getTaskEditStatus();
      }
    },
    mounted(){
      this.onResize();
      this.$store.dispatch( 'loadPatientEdit', {slug:  this.$route.params.id} );
      
      var options = this.$store.getters.getTaskOptions;
      options.selectedPatient = this.$route.params.id;
      this.$store.dispatch( 'setTaskOptions', {options:  options} );
    },
    beforeRouteLeave(to, from, next) {
      var options = this.$store.getters.getTaskOptions;
      options.selectedPatient = null;
      this.$store.commit( 'setTaskOptions', options );
      if(to.name!=="dzialaniePacjenta"){
        this.$store.dispatch( 'loadPatientEditDefault'); 
        this.$store.dispatch( 'loadPatientAddDefault');
        this.$store.commit("setFlowsAttachmentFileContent", {
          file_name:null,
          file_content:null,
          file_type:null,
          file_path:null});
      }
      next();
    },
    methods: {
      onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight - 64 - 64 - 64 - 40 }
      },
      close(){
        this.$v.$reset();
        this.$router.go(-1)
        this.$store.dispatch( 'loadPatientEditDefault'); 
        this.$store.dispatch( 'loadPatientAddDefault');
        this.$store.commit("setFlowsAttachmentFileContent", {
          file_name:null,
          file_content:null,
          file_type:null,
          file_path:null});
      },
      closeDialog () {
        this.$v.$reset();
        this.dialog = false
        setTimeout(() => {
          this.$store.dispatch( 'loadPatientEdit', {slug:  this.editedItem.id} )
          this.$store.commit('setPatientsRecommendationLookup',[]);
        }, 300)
      },
      //  handleSubmit () {
      //   this.$v.$touch()
      //   if (this.$v.$invalid) {

      //   } else {
         
      //       if (this.itemEditing) {
      //         //edycja
      //         this.$store.dispatch( 'editPatient', {
      //           slug: this.editedItem.id,
      //           name:  this.editedItem.name,
      //           type:  this.editedItem.type,
      //           post_code: this.editedItem.post_code,
      //           city: this.editedItem.city,
      //           address: this.editedItem.address,
      //           phone:this.editedItem.phone,
      //           email: this.editedItem.email,
      //           logo: this.editedItem.logo,
      //           nip: this.editedItem.nip,
      //           regon: this.editedItem.regon,
      //           referrals_email: this.editedItem.referrals_email,
      //           contacts: this.editedItem.contacts
      //         });
      //       } else {//new item
      //         this.$store.dispatch( 'addPatient', {
      //             name:  this.editedItem.name,
      //             type:  this.editedItem.type,
      //             post_code: this.editedItem.post_code,
      //             city: this.editedItem.city,
      //             address: this.editedItem.address,
      //             phone:this.editedItem.phone,
      //             email: this.editedItem.email,
      //             logo: this.editedItem.logo,
      //             nip: this.editedItem.nip,
      //             regon: this.editedItem.regon,
      //             referrals_email: this.editedItem.referrals_email,
      //             contacts: this.editedItem.contacts
      //         });

      //       }
      //   }
      // },
    },
  }
</script>

