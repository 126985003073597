<template>
<v-sheet :key="elementKey" :style="subItem && !filesView?'margin-left:20%;margin-right:15px;':''" style="background-color:#CECECE;">
  <v-icon v-if="!filesView" color="grey darken-1" style="position:absolute;margin-left:30px;" :style="date.length>0?'margin-top:33px;':'margin-top:-11px;'" size="20">mdi-network-strength-4 mdi-rotate-225</v-icon>
  <span v-if="date.length>0" class="text-body-2"><b><text-highlight :queries="search">{{date}}</text-highlight></b> <text-highlight :queries="search">{{time}}</text-highlight></span>
  <v-sheet v-if="!filesView" class="mt-5 text-body-2" style="border:1px solid grey !important;" :color="editable?'light-blue lighten-5':(!subItem?color:'')"  outlined :width="subItem?'100%':'75%'" rounded>
    <v-row class="px-1 py-1">
      <v-col>
        <v-icon size="20" class="mr-2">{{icon}}</v-icon>
        <router-link v-if="!subItem" style="text-decoration: none;" :to="{name:'realizacja',params:{id:id}}"><b><text-highlight :queries="search">{{title}}</text-highlight></b></router-link>
        <span v-else><b><text-highlight :queries="search">{{title}}</text-highlight></b></span>
        <v-icon v-if="!chooseMode && editable && !readonly && subItem" @click="edit()" color="primary" size="20" class="ml-1">mdi-pencil</v-icon>
        <v-icon v-if="!chooseMode && editable && !readonly && subItem" @click="del()" color="secondary" size="20" class="ml-1">mdi-delete</v-icon>
        <v-icon v-if="chooseMode" color="secondary" class="ml-1" size="20" @click="chooseAttachment">mdi-plus</v-icon>
        <span class="ml-5"><text-highlight :queries="search">{{subTitle}}</text-highlight></span>
      </v-col>
      <v-col md="auto" class="text-caption" v-html="htmlHighlight(status,search)"></v-col>
      <slot name="expand"></slot>
    </v-row>
    <v-row class="px-3 pt-0 pb-3 mt-0" v-if="this.$slots.body">
      <v-col class="py-0">
        <slot name="body"></slot>
      </v-col>
    </v-row>
    <v-row class="px-3 pt-0 pb-3 mt-0" v-if="this.$slots.files">
      <v-col class="pt-0 pb-1">
        <slot name="files"></slot>
      </v-col>
    </v-row>
  </v-sheet>
   <v-sheet v-else style="background-color:#CECECE;">
     <v-row class="px-3 pt-0 pb-3 mt-0" v-if="this.$slots.files">
      <v-col class="pt-0 pb-1">
        <slot name="files"></slot>
      </v-col>
    </v-row>
   </v-sheet>
</v-sheet>
</template>

<script>
import * as easings from 'vuetify/es5/services/goto/easing-patterns'
export default {
  props:{
    elementKey:{
      type:Number,
      default:1
    },
    id:{
      type:Number,
      default:null
    },
    date:{
      type:String,
      default:""
    },
    time:{
       type:String,
       default:""
    },
    title:{
     type:String,
     default:""
    },
    subTitle:{
     type:String,
     default:""
    },
    status:{
     type:String,
     default:""
    },
    icon:{
     type:String,
     default:""
    },
    color:{
     type:String,
     default:""
    },
    subItem:{
      type:Boolean,
      default:false
    },
    filesView:{
      type:Boolean,
      default:false
    },
    tableView:{
      type:Boolean,
      default:false
    },
    editable:{
      type:Boolean,
      default:false
    },
    readonly:{
      type:Boolean,
      default:false
    },
    chooseMode:{
        type:Boolean,
        default:false
      },
    search:{
      type:String,
      default:""
    },
  },
  data: () => ({
    expand:true,
  }),
  methods: {
    htmlHighlight(words,query){
      if(!query || !words)return words
      var iQuery = new RegExp(query, "ig");
      return words.toString().replace(iQuery, function(matchedTxt,a,b){
          return ('<mark class=\'text__highlight\'>' + matchedTxt + '</mark>');
      });
    },
    chooseAttachment(){
      this.$emit('chooseAttachment', this.id);
    },
    edit(){
      this.$emit('edit', this.id);
    },
    del(){
      this.$emit('del', this.id);
    }
  },
 
}
</script>
<style scoped>

</style>