<template>
      <v-row>
      <v-col>
          <v-card class="fill-height" style="background-color:#F5F5F5;" :loading="appointmentAddStatus===1 || appointmentEditLoadStatus===1 || appointmentEditStatus===1">
                <v-toolbar style="background-color:#F5F5F5;" flat>
                  <v-btn color="primary" small @click="close()"><v-icon>mdi-arrow-left</v-icon>Wróć</v-btn>
                  <v-toolbar-title class="ml-5"><strong><h3>
                    {{ editedItem.patient.name?editedItem.patient.name.toUpperCase():'' }} {{ editedItem.patient.surname?editedItem.patient.surname.toUpperCase():'' }} / {{editedItem.patient.age}}
                    {{editedItem.patient.phone?' - '+editedItem.patient.phone:''}}
                    </h3></strong></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn v-if="editedItem.category_id==5 && this.editedItem.video && !calling" small class="mr-3" color="secondary" @click="joinMeeting"><v-icon left>mdi-video</v-icon>DOŁĄCZ DO WIDEO</v-btn>
                  <v-switch v-model="header" label="Nagłówek" class="mr-3 mt-5" />
                  <v-menu bottom right v-model="statusMenu">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  outlined
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                >
                                  <span>{{_.find(lookups.appointment_status,{'value':editedItem.status}).text}}</span>
                                  <v-icon right>
                                    mdi-menu-down
                                  </v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item v-for="(type) in lookups.appointment_status" :key="type.value" @click.capture.stop="changeStatus(type.value)">
                                  <v-list-item-content @click="">
                                    <v-list-item-title>{{ type.text }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                    </v-menu>
                  <v-btn color="primary" small :to="{ name: 'dzialanie', params: { id: editedItem.id } }" class="ml-2" ><v-icon>mdi-menu</v-icon></v-btn>
                  <!-- <v-btn :disabled="!mainEventChanged" color="secondary" small @click="handleSubmit" class="ml-2" >Zapisz</v-btn> -->
                </v-toolbar>
          

          <v-row class="mt-0">
            <v-col cols="7" v-show="!attachmentEditorFullScreen" class="pl-5 pt-0" v-resize="onResize">
              <med-flow 
              :chooseMode="attachmentEditedItem.tab==2 && attachmentEditedItem.type!==70"
              :chooseFileMode="attachmentEditedItem.tab==1 && attachmentEditedItem.type!==70"
              :key="componentKey" 
              :flowHeight="windowSize.y" 
              :flowWidth="windowSize.x" 
              :editableAppointment="editedItem.id" 
              @newFile="newFileChoosed"
              @edit="editAttachment($event)" 
              @del="deleteAttachment($event)" />
            </v-col>
            <v-col :class="attachmentEditorFullScreen?'pl-5 pr-5 pt-0':'pl-0 pr-5 pt-0'">
                <v-card class="mb-3" v-if="header" v-show="!attachmentEditorFullScreen">
                  <div style="position:absolute;right:2px;top:2px;">
                    <v-avatar 
                                  rounded 
                                  size="30" 
                                  v-for="t in editedItem.team" 
                                  :key="t.id" 
                                  :color="t.color?t.color:'grey'" 
                                  class="ml-2" 
                                  ><span class="white--text">{{t.name[0]+t.surname[0]}}</span></v-avatar>
                  </div>
                  <v-container fluid>
                  <v-card-text>
                          <v-row class="child-flex" >
                            <v-col cols="12">
                              <h3>{{editedItem.service.name}} </h3>
                            </v-col>
                            <v-col>
                              <h3>{{editedItem.clinic?editedItem.clinic.name:''}} {{editedItem.room?' - '+editedItem.room:''}}</h3>
                            </v-col>
                            <v-col md="auto">
                              <h3>{{editedItem.date_from}} ({{duration}}min)</h3>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field outlined dense v-model="editedItem.info" label="Notatka" hide-details>
                                <template v-slot:append>
                                  <v-icon @click="editAppointmentInfo" color="green darken-3">mdi-content-save</v-icon>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                  </v-card-text>
                  
                  </v-container>
                </v-card>  
                
                <!-- <v-container v-if="editedItem.category_id==5 && this.editedItem.video && !calling" class="pt-0 d-flex justify-center">
                  <v-btn color="secondary" @click="joinMeeting"><v-icon left>mdi-video</v-icon>DOŁĄCZ DO WIDEO</v-btn>  
                </v-container> -->
                <div v-show="editedItem.category_id==5 && this.editedItem.video && calling && !attachmentEditorFullScreen" id="iframe-container" style="width:100%;height:350px;" class="mb-3"></div>
                
                <attachment-editor ref="attEditor" @close="closeAttachment" :height="attachmentEditorHeight"  v-if="attachmentEditor">
                  <template v-slot:maximize>
                    <v-icon v-if="!attachmentEditorFullScreen" @click="attachmentEditorFullScreen = true" color="grey darken-2" size="30" class="mr-2 ml-2">mdi-window-maximize</v-icon>
                    <v-icon v-else @click="attachmentEditorFullScreen = false" color="grey darken-2" size="30" class="mr-2 ml-2">mdi-window-minimize</v-icon>
                  </template>
                </attachment-editor>
                <v-card v-show="!attachmentEditorFullScreen" v-if="!attachmentEditor && header">
                  <log-preview :type="2" :ref_id="editedItem.id" />
                </v-card>
            </v-col>  
          </v-row>
          
        </v-card>
      <confirm ref="confirm"></confirm>
      </v-col>
      <v-col md="auto">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" fab outlined large color="blue darken-4" height="63" class="px-0 mb-5" style="border:2px solid;"><v-icon size="43">{{editedItem.category.icon}}</v-icon></v-btn>
            </template>
            <span>{{editedItem.category.name}}</span>
        </v-tooltip>

        <!-- <v-container class="menu pa-0 d-flex flex-column align-center mb-5">
            <v-btn color="green darken-3" icon><v-icon size="30">mdi-content-save</v-icon></v-btn>
            <span class="text-caption font-weight-medium" style="font-size:0.5em;margin-top:-5px;">Zapisz</span>
        </v-container> -->

        <v-container class="menu pa-0 d-flex flex-column align-center">
            <v-btn :disabled="!editedItem.id || appointmentAddStatus===1 || appointmentEditLoadStatus===1 || appointmentEditStatus===1" color="blue darken-3" icon @click="newAttachment(10)"><v-icon size="35">mdi-note-text-outline</v-icon></v-btn>
            <span class="text-caption font-weight-medium" style="font-size:0.5em;margin-top:-5px;">Opis</span>
        </v-container>

        <v-container class="menu pa-0 d-flex flex-column align-center">
            <v-btn :disabled="!editedItem.id || appointmentAddStatus===1 || appointmentEditLoadStatus===1 || appointmentEditStatus===1" color="purple darken-3" icon @click="newAttachment(20)"><v-icon size="35" v-if="editedItem.cs && editedItem.cs.patient_info">mdi-note</v-icon><v-icon v-else size="35">mdi-note-outline</v-icon></v-btn>
            <span class="text-caption font-weight-medium" style="font-size:0.5em;margin-top:-5px;">Zalecenia</span>
        </v-container>

        <v-container v-if="editedItem.video" class="menu pa-0 d-flex flex-column align-center">
            <v-btn :disabled="!editedItem.id || appointmentAddStatus===1 || appointmentEditLoadStatus===1 || appointmentEditStatus===1" color="green darken-3" icon @click="newAttachment(30)"><v-icon size="35">mdi-email-send</v-icon></v-btn>
            <span class="text-caption font-weight-medium" style="font-size:0.5em;margin-top:-5px;">Link</span>
        </v-container>

        <v-container class="menu pa-0 d-flex flex-column align-center">
            <v-btn :disabled="!editedItem.id || appointmentAddStatus===1 || appointmentEditLoadStatus===1 || appointmentEditStatus===1" color="red darken-3" icon @click="newAttachment(40)"><v-icon size="35">mdi-email-arrow-right-outline</v-icon></v-btn>
            <span class="text-caption font-weight-medium" style="font-size:0.5em;margin-top:-5px;">E-Mail</span>
        </v-container>

                      <v-container v-if="user.permissions.tasks >= 5" class="menu pa-0 d-flex flex-column align-center">
                        <v-btn :disabled="!editedItem.id || appointmentAddStatus===1 || appointmentEditLoadStatus===1 || appointmentEditStatus===1" color="green darken-3" icon @click="newAttachment(50)"><v-icon size="35">mdi-clipboard-edit-outline</v-icon></v-btn>
                        <span class="text-caption font-weight-medium" style="font-size:0.5em;">Zadanie</span>
                      </v-container>

        <v-container v-if="user.permissions.patient >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn :disabled="!editedItem.id || appointmentAddStatus===1 || appointmentEditLoadStatus===1 || appointmentEditStatus===1" color="orange darken-3" icon @click="newAttachment(60)"><v-icon size="35">mdi-file-table-box-outline</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Skierowanie</span>
        </v-container>
        <v-container v-if="user.permissions.patient >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn :disabled="!editedItem.id || appointmentAddStatus===1 || appointmentEditLoadStatus===1 || appointmentEditStatus===1" color="purple darken-3" icon @click="newAttachment(70)"><v-icon size="35">mdi-alpha-r-circle-outline</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Recepta</span>
        </v-container>
       <v-container v-if="user.permissions.patient >= 5" class="menu pa-0 d-flex flex-column align-center">
          <v-btn :disabled="!editedItem.id || appointmentAddStatus===1 || appointmentEditLoadStatus===1 || appointmentEditStatus===1" color="lime darken-3" icon @click="newAttachment(80)"><v-icon size="35">mdi-chart-box-outline</v-icon></v-btn>
          <span class="text-caption font-weight-medium" style="font-size:0.5em;">Wyniki</span>
        </v-container>

      </v-col>
      <confirm ref="confirm"></confirm>
      </v-row>
</template>

<script>
  import confirm from '../components/Confirm'
  import required from 'vuelidate/lib/validators/required'
  import moment from 'moment';
  import AttachmentEditor from '../components/AttachmentEditor.vue';
  import LogPreview from '../components/LogPreview.vue';
  
  import MedFlow from '../components/MedFlow.vue';
  moment.locale('pl');

  import DailyIframe from '@daily-co/daily-js';

  export default {
    components:{confirm,AttachmentEditor, MedFlow, LogPreview},
    data: () => ({
      windowSize: {
        x: 0,
        y: 0,
      },
      componentKey:0,
      statusMenu:false,
      attachmentEditor:false,
      attachmentEditing:false,
      attachmentEditorFullScreen:false,
      attachmentType:null,
      callFrame:null,
      calling:false,
      taskDialog:false,
      header:true
    }),
    validations(){
        return {
          editedItem:{
            patient_id: {
              required
            },
            date_from:{
              required
            },
            // user_id: {
            //   required
            // },
            // clinic_id: {
            //   required
            // },
          }
        }
    },
    watch: {
      appointmentEditStatus(val){
        if(val===2){
          //this.componentKey++;
        }
      },
      appointmentEditLoadStatus(val){
        if(val===2){

          var options = this.$store.getters.getFlowOptions;
          //if(this.editedItem.category_id==1){//badanie
          //  options.toDate = moment(this.editedItem.date_from).toISOString()
          //  options.selectedAppointment = this.editedItem.id;
          //  options.selectedPatient = this.editedItem.patient_id;
          //} else{
            options.toDate = moment(this.editedItem.date_from).toISOString()
            options.selectedAppointment = this.editedItem.id;
            options.selectedPatient = this.editedItem.patient_id;
            //options.selectedAppointment = null;
          //}
          this.$store.dispatch( 'setFlowOptions', {options:  options} );

          //wideo
          if(this.editedItem.category_id==5){
            this.callFrame = DailyIframe.createFrame(document.getElementById('iframe-container'),{
              showLeaveButton: true,
              showFullscreenButton: true
            });
          }

        }
      },
      appointmentAddStatus(val){
        if(val===2){

        }
      },
      // attachmentEditStatus(val){
      //    if(val===2){
      //     this.componentKey++;
      //    }
      // },
      attachmentAddStatus(val){
        if(val===2){
          this.componentKey++;
         }
      },
      attachmentDeleteStatus(val){
        if(val===2){
          this.componentKey++;
         }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getUser;
      },
      lookups(){
        return this.$store.getters.getLookups;
      },
      itemEditing(){
        return !_.isEmpty(this.$route.params) && this.$route.params.id;
      },
      editedItem(){
        return this.$store.getters.getAppointmentEdit;
      },
      appointmentEditLoadStatus(){
        return this.$store.getters.getAppointmentEditLoadStatus();
      },
      appointmentEditStatus(){
        return this.$store.getters.getAppointmentEditStatus();
      },
      appointmentEditText(){
        return this.$store.getters.getAppointmentEditText
      },
      appointmentAddStatus(){
        return this.$store.getters.getAppointmentAddStatus();
      },
      appointmentAddText(){
        return this.$store.getters.getAppointmentAddText;
      },
      duration:{
        get(){
          if(!this.editedItem.date_from)return null;
          return moment.duration(moment(this.editedItem.date_to).diff(moment(this.editedItem.date_from))).asMinutes();
        }
      },
      attachmentEditedItem(){
        return this.$store.getters.getAttachmentEdit;
      },
      attachmentEditLoadStatus(){
        return this.$store.getters.getAttachmentEditLoadStatus
      },
      attachmentEditStatus(){
        return this.$store.getters.getAttachmentEditStatus();
      },
      attachmentAddStatus(){
        return this.$store.getters.getAttachmentAddStatus();
      },
      attachmentDeleteStatus(){
        return this.$store.getters.getAttachmentDeleteStatus();
      },
      attachmentEditorHeight(){
        var max = this.windowSize.y + 72;
        if(this.header && !this.attachmentEditorFullScreen)max = max - 136 - 64;//64 na pole notatki
        var videoH = 350;
        if(this.editedItem.video && this.calling && !this.attachmentEditorFullScreen) max = max - videoH - 10;
        return max;
      },
    },
    mounted(){
      this.onResize();

      if(this.lookups.roles.length>0){
        this.itemEditing && this.$store.dispatch( 'loadAppointmentEdit', {slug:  this.$route.params.id} ) ;
      }else{
        this.$store.dispatch( 'loadLookups' );
        setTimeout(() => {
          this.itemEditing && this.$store.dispatch( 'loadAppointmentEdit', {slug:  this.$route.params.id} );
        }, 2000)
      }
    },
    beforeRouteLeave (to, from, next) {
      // this.$v.$reset();
      this.$store.dispatch( 'loadAppointmentEditDefault'); 
      // this.$store.dispatch( 'loadAppointmentAddDefault');
      // this.$store.commit('setReferenceEvents',[]);
      next();
    },
    methods: {
      onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight - 64 - 64 - 64 - 40 }
      },
      
      moment: function (val) {
        return moment(val);
      },
      joinMeeting(){
        var that = this;
        this.callFrame.on('left-meeting', (event) => { 
              that.calling = false;
        });
        this.callFrame.join({ url: 'https://hifu.daily.co/' + this.editedItem.video });
        this.calling = true;
        this.header = false;
      },
      newAttachment(type){
        this.$store.commit( 'setAttachmentEditDefault'); 
        this.$store.commit( 'setNewFileDefault');
        this.attachmentEditedItem.appointment_id = this.editedItem.id;
        this.attachmentEditedItem.type = type;
        this.attachmentEditedItem.header = _.find(this.lookups.attachment_type,{'value':type}).header;
        this.attachmentEditedItem.orig = JSON.parse(JSON.stringify(this.attachmentEditedItem));

        //jeżeli to link do spotkania
        if(type==30){
          this.attachmentEditedItem.body = "https://hifu.daily.co/" + this.editedItem.video;
        }else if(type==40){//e-mail
          this.attachmentEditedItem.bodyHTML= true;
          this.attachmentEditedItem.content.recipient = this.editedItem.patient.email?this.editedItem.patient.email:null; 
          this.attachmentEditedItem.content.header = this.editedItem.patient.name + ' ' + this.editedItem.patient.surname;
          //this.attachmentEditedItem.body = '<p></p>' + this.user.info;
        }else if(type==60){//skierowanie
          this.attachmentEditedItem.content.referral.patient.name = this.editedItem.patient.name + ' ' + this.editedItem.patient.surname;
          this.attachmentEditedItem.content.referral.patient.pesel = this.editedItem.patient.pesel;
          this.attachmentEditedItem.content.referral.patient.age = this.editedItem.patient.age;
          this.attachmentEditedItem.content.referral.patient.phone = this.editedItem.patient.phone;
          this.attachmentEditedItem.content.referral.patient.email = this.editedItem.patient.email;
          this.attachmentEditedItem.content.referral.patient.address = 
              (this.editedItem.patient.address?this.editedItem.patient.address:'') + 
              (this.editedItem.patient.house?' ' + this.editedItem.patient.house:'') + 
              (this.editedItem.patient.apartment?'/'+this.editedItem.patient.apartment:'') + 
              (this.editedItem.patient.post_code?', ' + this.editedItem.patient.post_code:'') + 
              (this.editedItem.patient.city?' ' + this.editedItem.patient.city:'');
          if(this.attachmentEditedItem.content.referral.patient.address == "")this.attachmentEditedItem.content.referral.patient.address = null;

        }else if(type==20){//zalecenia
          this.attachmentEditedItem.body = this.editedItem.cs && this.editedItem.cs.patient_info ? this.editedItem.cs.patient_info : null; 
        }else if(type==70){//e-recepta
          this.attachmentEditedItem.content.prescription.patient.id = this.editedItem.patient.id;
          this.attachmentEditedItem.content.prescription.patient.sex = this.editedItem.patient.sex;
          this.attachmentEditedItem.content.prescription.patient.name = this.editedItem.patient.name;
          this.attachmentEditedItem.content.prescription.patient.surname = this.editedItem.patient.surname;
          this.attachmentEditedItem.content.prescription.patient.pesel = this.editedItem.patient.pesel;
          this.attachmentEditedItem.content.prescription.patient.birth_date = this.editedItem.patient.birth_date;
          this.attachmentEditedItem.content.prescription.patient.address = this.editedItem.patient.address;
          this.attachmentEditedItem.content.prescription.patient.house = this.editedItem.patient.house;
          this.attachmentEditedItem.content.prescription.patient.apartment = this.editedItem.patient.apartment;
          this.attachmentEditedItem.content.prescription.patient.city = this.editedItem.patient.city;
          this.attachmentEditedItem.content.prescription.patient.post_code = this.editedItem.patient.post_code;
          this.attachmentEditedItem.content.prescription.patient.gus = this.editedItem.patient.gus;
          this.attachmentEditedItem.content.prescription.patient.insurance = this.editedItem.patient.insurance;
          this.attachmentEditedItem.content.prescription.patient.phone = this.editedItem.patient.phone;
          
          this.attachmentEditedItem.content.prescription.author.name = this.user.name;
          this.attachmentEditedItem.content.prescription.author.surname = this.user.surname;
          this.attachmentEditedItem.content.prescription.author.pwz = this.user.pwz;

          if(this.editedItem.clinic){
            this.attachmentEditedItem.content.prescription.clinic.name = this.editedItem.clinic.name;
            this.attachmentEditedItem.content.prescription.clinic.address = this.editedItem.clinic.address;
            this.attachmentEditedItem.content.prescription.clinic.house = this.editedItem.clinic.house;
            this.attachmentEditedItem.content.prescription.clinic.city = this.editedItem.clinic.city;
            this.attachmentEditedItem.content.prescription.clinic.post_code = this.editedItem.clinic.post_code;
            this.attachmentEditedItem.content.prescription.clinic.regon = this.editedItem.clinic.regon;
            this.attachmentEditedItem.content.prescription.clinic.nfz = this.editedItem.clinic.nfz;
            this.attachmentEditedItem.content.prescription.clinic.phone = this.editedItem.clinic.phone;
            this.attachmentEditedItem.content.prescription.oid = this.editedItem.clinic.erecepta_oid;
            this.attachmentEditedItem.content.prescription.rpwdl = this.editedItem.clinic.erecepta_rpwdl;
          }
          
        }
        
        this.attachmentEditor = true;
      },
      closeAttachment(){
        this.$store.commit( 'setAttachmentEditDefault'); 
        this.$store.commit( 'setNewFileDefault');
        this.attachmentEditorFullScreen = false;
        this.attachmentEditor = false;
      },
      editAttachment(id){
        this.$store.commit( 'setNewFileDefault');
        this.$store.dispatch( 'loadAttachmentEdit',{slug:id}); 
        this.attachmentEditor = true;
      },
      deleteAttachment(id){
        this.$refs.confirm.open('Usuń', 'Czy na pewno chcesz usunać element?', { color: 'error' }).then((confirm) => {
            if(confirm){
               this.$store.dispatch( 'forceDeleteAttachment', {slug:  id});
               this.attachmentEditor = false;
               this.$store.commit( 'setAttachmentEditDefault'); 
               this.$store.commit( 'setNewFileDefault');
            }
        })  
      },
      changeStatus(status){
        this.editedItem.status = status;
        this.statusMenu = false;
        this.$store.dispatch( 'editAppointmentStatus', {
          slug: this.editedItem.id,
          status:this.editedItem.status,
        });
      },
      editAppointmentInfo(){
        this.$store.dispatch( 'editAppointmentInfo', {
          slug: this.editedItem.id,
          info:this.editedItem.info,
        });
      },
      close(){
        this.$v.$reset();
        this.$store.dispatch( 'loadAppointmentEditDefault'); 
        this.$store.dispatch( 'loadAppointmentAddDefault');
        this.$router.go(-1);
      },
      newFileChoosed(){
        this.$refs.attEditor.itemChanged = true;
      }
    },
  }
</script>
<style scoped lang="scss">
.menu{
  margin-bottom: 1em;
  padding-top:0px;
}
</style>
